import { useState } from "react";
import HeroImg from "../img/map_alan_2-edited.png";
import styles from "../styles/Hero.module.css";
import { ChevronsRight } from "react-feather";
import ITServices from "./ITServices";

const Hero = () => {
	const [showServices, setShowServices] = useState(false);

	return (
		<section id="home" data-name="home" className="home">
			<div className="wrapper">
				<div className={styles.container}>
					<div className={styles.heroLeft}>
						<h2 className={styles.heading2}>Empowering Technology For The Future</h2>
						<p className={styles.paragraph}>
							We Analyze, Transform and create digital proven solutions that will
							redefine the future of your ideas.
						</p>
						<div className={styles.buttonContainer}>
							<button
								className={styles.buttonLg}
								onClick={() => setShowServices(true)}
							>
								Learn More <ChevronsRight />
							</button>
						</div>
					</div>
					<div className={styles.heroRight}>
						{/* <div className={styles.imageDiv}> */}
						<img src={HeroImg} alt="heroimage" className={styles.heroImage} />
						{/* </div> */}
					</div>
				</div>
			</div>
			<ITServices show={showServices} onHide={() => setShowServices(false)} />
		</section>
	);
};

export default Hero;
