import Copyright from "../img/copyright.png"
import styles from "../styles/Footer.module.css"

const Footer = () => {
   return (
				<footer id="footer" className="footer" data-name="footer">
					<div className="wrapper">
						<div className={styles.container}>
							<span>
								<img
									src={Copyright}
									alt="copyright-icon"
									width="15px"
									height="15px"
								/>
							</span>
							&nbsp;<span>2022 ALAN_TURING CODES. All rights reserved</span>
						</div>
					</div>
				</footer>
			);
}

export default Footer
