const data = [
	{
		heading: "Software Development",
		desc:
			"Build a strong presence, attract the right clients, and let the world in on your vision. Our high-performance software application services will make your brand exceptional, Increase productivity and measure impact with our secure software solutions.",
	},
	{
		heading: "Product Design",
		desc:
			"We lead your clients to engage with your products by designing and developing a friendly interface that connects with them. We ensure your products are creatively designed to project your ideas to the world. We polish your existing products and transform them to become future-forward through research and audits.",
	},
	{
		heading: "Cloud Computing",
		desc:
			"Enjoy the privileges of securely storing data for the future without the fear of ‘what ifs’. Experience a cost-effective, light weight and easily accessible cloud solution.",
	},

	{
		heading: "Application Management",
		desc:
			"A leading brand in Hybrid and Native Applications. We engage bespoke strategies that will help you build interactive and responsive connections with your target audience and also managing of the system applications.",
	},
	
];

export default data;
