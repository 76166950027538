import React, {useState} from "react";
import styles from "../styles/Contact.module.css";
import Phone from "../img/telephone.png";
import Mail from "../img/email.png";
import Location from "../img/location.png";
import LinkedIn from "../img/linkedin.png";
import Twitter from "../img/twitter.png";
import Facebook from "../img/facebook.png";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const Contact = () => {
	const [isSent, setIsSent] = useState(false);
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [captcha, setCaptcha] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		const newMessage = {
			email,
			message,
			captcha,
		};
		try {
			const res = await axios.post(
				"https://mailer-api-server.herokuapp.com/api/v1/contact",
				newMessage,
			);
			if (res.data.success === true) {
				setEmail("");
				setMessage("");
				setCaptcha("");
				setIsSent(true);
				setTimeout(() => {
					setIsSent(false);
				}, 3000);
			}
		} catch (err) {
			if (err.response.data.success === false) {
				setIsSent(false);
			}
		}
	};

	return (
		<section id="contact" className="contact" data-name="contact">
			<div className="wrapper">
				<div className={styles.container}>
					<div className={styles.contactBorder}>
						<div className={styles.contactLeft}>
							<div className={styles.contactHeading}>Contact Us</div>
							<div className={styles.contactBody}>
								<div className={styles.infoControl}>
									<div className={styles.icons}>
										<img src={Phone} alt="Phone-icon" width="24px" height="24px" />
									</div>
									<div className={styles.iconDetails}>
										<span className={styles.phoneSpan}>
											<span>+234 (0) 8141740064</span>&nbsp;
										</span>
									</div>
								</div>
								<div className={styles.infoControl}>
									<div className={styles.icons}>
										<img src={Mail} alt="Mail-icon" width="24px" height="24px" />
									</div>
									<div className={styles.iconDetails}>info@alanturingcodes.com</div>
								</div>
								<div className={styles.infoControl}>
									<div className={styles.icons}>
										<img src={Location} alt="Location-icon" width="24px" height="24px" />
									</div>
									<div className={styles.iconDetails}>Zone 2, Wuse, FCT, Abuja.</div>
								</div>
								<div className={styles.infoControl}>
									<div className={styles.icons}>
										<img src={LinkedIn} alt="LinkedIn-icon" width="24px" height="24px" />
									</div>
									<div className={styles.iconDetails}>ALAN-TURING CODES</div>
								</div>
								<div className={styles.infoControl}>
									<div className={styles.icons}>
										<img src={Twitter} alt="Twitter-icon" width="24px" height="24px" />
									</div>
									<div className={styles.iconDetails}>ALAN-TURING CODES</div>
								</div>
								<div className={styles.infoControl}>
									<div className={styles.icons}>
										<img src={Facebook} alt="Facebook-icon" width="24px" height="24px" />
									</div>
									<div className={styles.iconDetails}>ALAN-TURING CODES</div>
								</div>
							</div>
						</div>
						<div className={styles.contactRight}>
							<div className={styles.contactHead}>Discuss a Project</div>
							<div className={styles.contactBody}>
								<form onSubmit={handleSubmit} className={styles.form}>
									<div className={styles.infoController}>
										<div className={styles.formInput}>
											<input
												type="email"
												name="email"
												placeholder="Your email address"
												required
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</div>
									</div>

									<div className={styles.formTextArea}>
										<textarea
											name="message"
											placeholder="Message"
											required
											value={message}
											onChange={(e) => setMessage(e.target.value)}
										></textarea>
									</div>
									<div className={styles.recaptcha}>
										<ReCAPTCHA
											className="g-recaptcha"
											sitekey="6LcrWE4gAAAAAP0PxrOkdw_usrJWmCa3H_F7vxza"
											onChange={(val) => setCaptcha(val)}
										/>
									</div>
									<div className={styles.infoController}>
										<div className={styles.formButton}>
											<input type="submit" value="Submit" />
										</div>
									</div>
								</form>
								{isSent && (
									<div className={styles.successContainer}>
										<p className={styles.success}>Message Submitted Successfully!</p>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
