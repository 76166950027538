import styles from "../styles/Modal.module.css";
import Card from "./Card";
import * as Icon from "react-feather";
import data2 from "./data2";
import DisplayModal from "./DisplayModal";

const ITStrategy = ({ show, onHide }) => {

	return (
		<>
			<DisplayModal
				show={show}
				fullscreen={true}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={onHide}
			>
				{/* <div className="wrapper"> */}
				<div className={styles.container}>
					<button className="modalCloseBtn" onClick={onHide}>
						&#10006;
					</button>
					<div className={styles.headContainer}>
						<h3 className={styles.textHeader}>IT BUSINESS STRATEGY</h3>
					</div>
					<div className={styles.mainContainer}>
						<div className={styles.cardContainer}>
							{data2.map((item, idx) => (
								<Card key={idx}>
									<div className={styles.cardHeader}>
										<div className={styles.cardEditIcon}>
											<Icon.Edit className={styles.editIcons} />
										</div>
									</div>
									<div className={styles.cardHeading}>
										<h3>{item.heading}</h3>
									</div>
									<div className={styles.cardBody}>{item.desc}</div>
								</Card>
							))}
						</div>
					</div>
					<p className={styles.slogan}>More Than Tech...</p>
				</div>
				{/* </div> */}
			</DisplayModal>
		</>
	);
};

export default ITStrategy;
