import { useState } from "react";
import styles from "../styles/Details.module.css";
import { ChevronsRight } from "react-feather";
import Listener from "../img/Active Listener.jpeg";
import ITStrategy from "./ITStrategy";

const Details = () => {
	const [showStrategy, setShowStrategy] = useState(false);

	return (
		<section id="detail" data-name="detail" className="detail">
			<div className="wrapper">
				<div className={styles.wrapped}>
					<div className={styles.imgContainer}>
						<img
							src={Listener}
							alt="ActiveListener"
							className={styles.detailsImage}
						/>
					</div>
					<div className={styles.textContainer}>
						<h1 className={styles.h1}>What we do for our clients</h1>
						<div className={styles.buttonContainer}>
							<button
								className={styles.buttonLg}
								onClick={() => setShowStrategy(true)}
							>
								Click to find out <ChevronsRight />
							</button>
						</div>
					</div>
					<div className={styles.parallel}></div>
				</div>
			</div>
			<ITStrategy show={showStrategy} onHide={() => setShowStrategy(false)} />
		</section>
	);
};

export default Details;
