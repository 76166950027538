import styles from "../styles/About.module.css";
import SmallCard from "./SmallCard";
import ProductIcon from "../img/product.png";
import NotepadIcon from "../img/notepad.png";
import DistributedIcon from "../img/distributed.png";
import TeamWorkIcon from "../img/teamwork.png";

const About = () => {
	return (
		<section id="about" data-name="about" className="about">
			<div className="wrapper">
				<div className={styles.container}>
				
					<h2 className={styles.h2}>Our Values</h2>
					<p className={styles.paragraph}>
						We are guided and led by strong values that drives what we do. These
						include:
					</p>
					<div className={styles.cardContainer}>
						<SmallCard>
							<div className={styles.cardHeader}>
								<div className={styles.cardEditIcon}>
									<img
										src={DistributedIcon}
										alt="iconmage"
										width="40px"
										height="40px"
									/>
								</div>
							</div>
							<div className={styles.cardHeading}>
								<h3>Integrity</h3>
							</div>
						</SmallCard>
						<SmallCard>
							<div className={styles.cardHeader}>
								<div className={styles.cardEditIcon}>
									<img
										src={TeamWorkIcon}
										alt="iconimage"
										width="40px"
										height="40px"
									/>
								</div>
							</div>
							<div className={styles.cardHeading}>
								<h3>Collaboration</h3>
							</div>
						</SmallCard>
						<SmallCard>
							<div className={styles.cardHeader}>
								<div className={styles.cardEditIcon}>
									<img src={ProductIcon} alt="iconimage" width="40px" height="40px" />
								</div>
							</div>
							<div className={styles.cardHeading}>
								<h3>Excellence</h3>
							</div>
						</SmallCard>
						<SmallCard>
							<div className={styles.cardHeader}>
								<div className={styles.cardEditIcon}>
									<img src={NotepadIcon} alt="iconimage" width="40px" height="40px" />
								</div>
							</div>
							<div className={styles.cardHeading}>
								<h3>Results</h3>
							</div>
						</SmallCard>
					</div>
					
				</div>
				<div className={styles.textContainer}>
						<h1 className={styles.h1}>About Alan-Turing Codes</h1>
				</div>
			</div>
		</section>
	);
};

export default About;
