import { useEffect, useState } from "react";
import styles from "../styles/Header.module.css";
import LogoImg from "../img/logo.png";
import { Link } from "react-scroll";
import ITServices from "./ITServices";
import ITStrategy from "./ITStrategy";



const Header = () => {
	const [colorChange, setColorChange] = useState(false);
    const [showStrategy, setShowStrategy] = useState(false);
	const [showServices, setShowServices] = useState(false);



	const changeNavbarColor = () => {
		if (window.scrollY >= 1) {
			setColorChange(true);
		} else {
			setColorChange(false);
		}
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			changeNavbarColor();
			window.addEventListener("scroll", changeNavbarColor);
			return () => window.removeEventListener("scroll", changeNavbarColor);
		}
	}, []);

	return (
		<nav className={colorChange ? "navBar colorChange" : "navBar"}>
			<div className="wrapper">
				<div className={styles.container}>
					<div className={styles.logo}>
						<img src={LogoImg} alt="logo" width="330px" height="100px" />
					</div>
					<ul className={styles.nav}>
						<li className="navItem">
							<Link
								activeClass="active"
								to="home"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#home"
							>
								Home
							</Link>
						</li>
						<li className="navItem">
							<Link
								activeClass="active"
								to="Business-Strategies"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#Business-Strategies"
								onClick={() => setShowStrategy(true)}

							>
								IT Business Strategies
							</Link>
							<ITStrategy
                          show={showStrategy}
                          onHide={() => setShowStrategy(false)}
                        />
						</li>
						
						<li className="navItem">
							<Link
								activeClass="active"
								to="IT-Services"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#IT-Services"
								onClick={() => setShowServices(true)}
							>
								IT Services
							</Link>
							<ITServices
                          show={showServices}
                          onHide={() => setShowServices(false)}
                        />
						</li>
						
						<li className="navItem">
							<Link
								activeClass="active"
								to="about"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#about"
							>
								About ATC
							</Link>
						</li>
						<li className="navItem">
							<Link
								activeClass="active"
								to="contact"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#contact"
							>
								Contact Us
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Header;
