const SmallCard = ({ children }) => {
	return (
		<div
			style={{
				// width: "237px",
				height: "205px",
				background: "#fff",
				boxShadow: "4px 8px 8px rgba(0, 0, 0, 0.25)",
				borderRadius: "10px",
			}}
		>
			{children}
		</div>
	);
};

export default SmallCard;
