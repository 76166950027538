import { useState } from "react";
import LogoImg from "../img/logo.png";
import { Link } from "react-scroll";
import ITServices from "./ITServices";
import ITStrategy from "./ITStrategy";
// import { useEffect, useState } from "react";

const SideBar = () => {
	const [showStrategy, setShowStrategy] = useState(false);
	const [showServices, setShowServices] = useState(false);
	// const [colorChange, setColorChange] = useState(false);

	// const changeNavbarColor = () => {
	// 	if (window.scrollY >= 1) {
	// 		setColorChange(true);
	// 	} else {
	// 		setColorChange(false);
	// 	}
	// };

	// useEffect(() => {
	// 	if (typeof window !== "undefined") {
	// 		changeNavbarColor();
	// 		window.addEventListener("scroll", changeNavbarColor);
	// 		return () => window.removeEventListener("scroll", changeNavbarColor);
	// 	}
	// }, []);

	return (
		// <!--Navbar-->
		<nav className="navbar navbar-dark red lighten-1 mb-4">
			<div className="navbar-brand">
				<img src={LogoImg} alt="logo" width="330px" height="100px" />
			</div>

			<button
				className="navbar-toggler second-button"
				type="button"
				data-toggle="collapse"
				data-target="#navbarSupportedContent23"
				aria-controls="navbarSupportedContent23"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<div className="animated-icon2">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</button>

			<div className="collapse navbar-collapse" id="navbarSupportedContent23">
				<ul className="navbar-nav mr-auto">
					<li className="nav-item">
						<span className="nav-link">
							<Link
								activeClass="active"
								to="home"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#home"
							>
								Home
							</Link>
						</span>
					</li>
					<li className="nav-item">
						<span className="nav-link">
							<Link
								activeClass="active"
								to="service"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#service"
								onClick={() => setShowStrategy(true)}

							>
								IT Business Strategies
							</Link>
							<ITStrategy
                          show={showStrategy}
                          onHide={() => setShowStrategy(false)}
                        />
						</span>
					</li>
					<li className="nav-item">
						<span className="nav-link">
							<Link
								activeClass="active"
								to="product"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#product"
								onClick={() => setShowServices(true)}
							>
								IT Services
							</Link>
							<ITServices
                          show={showServices}
                          onHide={() => setShowServices(false)}
                        />
						</span>
					</li>
					<li className="nav-item">
						<span className="nav-link">
							<Link
								activeClass="active"
								to="about"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#about"
							>
								About ATC
							</Link>
						</span>
					</li>
					<li className="nav-item">
						<span className="nav-link">
							<Link
								activeClass="active"
								to="contact"
								spy={true}
								smooth={true}
								offset={-20}
								duration={500}
								href="/#contact"
							>
								Contact Us
							</Link>
						</span>
					</li>
				</ul>
			</div>
		</nav>
		// <!--/.Navbar-->
	);
};

export default SideBar;
