const data2 = [
    {
		heading: "Software Testing",
		desc:
			"Your software goes a thorough testing process to mitigate errors or glitches in its development. We ensure its efficient usability, functionality and high-performance delivery.",
	},
	{
		heading: "Cyber Security",
		desc:
			"We apply an effective and efficient safe security strategy to position your enterprise against cyber-attacks. We ensure your databases, clouds, systems and connected devices are well managed to prevent breaches, identity theft and risks.",
	},
	{
		heading: "IT Consultancy",
		desc:
			"Your team speaks about your results, and with the right team you can increase productivity just the way you’ve dreamed; and more.",
	},
	{
		heading: "Network Operations",
		desc:
			"We maintain and monitor range of network operations to allow you focus on how to grow your business.",
	},
	
];
export default data2;