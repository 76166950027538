import styles from "../styles/Card.module.css";

const Card = ({ children }) => {
	return (
		<div
			className={styles.customCard}
			style={{
				padding: "5px 5px 40px 5px",
				background: "#fff",
				boxShadow: "4px 8px 8px rgba(0, 0, 0, 0.25)",
				borderRadius: "10px",
			}}
		>
			{children}
		</div>
	);
};

export default Card;
