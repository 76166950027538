import styles from "../styles/Services.module.css";
import { ChevronsRight } from 'react-feather';

// import Card from "./Card";
// import * as Icon from "react-feather";
// import data from "./data";

const Services = () => {
	return (
		<section id="service" data-name="service" className="service">
			<div className="wrapper">
				<div className={styles.container}>
					<h1 className={styles.h1}>Who we are</h1>
					<p className={styles.paragraph}>
						We are one of the leading IT companies in Nigeria driven by results
					 	and motivated by creativity through technology. We put in an excellent
					 	thought process that births a creative path for your ideas, thereby delivering
					   	a high-end productivity which positions you as a thought leader. Our team of 
					   	experienced expertise follow through an intense thought process.
					</p>
					<div className={styles.buttonContainer}>
					<a href="#contact"><button className={styles.buttonLg}>Discuss a Project<ChevronsRight/></button></a>
					</div>
					{/* <div className={styles.cardContainer}>
						{data.map((item, idx) => (
							<Card key={idx}>
								<div className={styles.cardHeader}>
									<div className={styles.cardEditIcon}>
										<Icon.Edit className={styles.editIcons} />
									</div>
								</div>
								<div className={styles.cardHeading}>
									<h3>{item.heading}</h3>
								</div>
								<div className={styles.cardBody}>{item.desc}</div>
							</Card>
						))}
					</div> */}
				</div>
			</div>
		</section>
	);
};

export default Services;
