import "bootstrap/dist/css/bootstrap.css";
import "./styles/globals.css";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Details from "./components/Details";
import About from "./components/About";
import Expert from "./components/Expert";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Consultancy from "./components/Consultancy";
import ITServices from "./components/ITServices";
import { ViewportProvider} from "./context/ViewportContext";
import Navbar from "./components/Navbar";
 
const App = () => {

	return (
		<ViewportProvider>
			<Navbar />
			<Hero />
			<Services />
			<Details />
			<About />
			<Expert />
			<Contact />
			<Footer />
			<Consultancy />
			<ITServices />
		</ViewportProvider>
	);
};

export default App;
