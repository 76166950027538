import React, { useContext } from "react";
import { ViewportContext } from "../context/ViewportContext";
import Header from "./Header";
import SideBar from "./SideBar";

const Navbar = () => {
	const { width } = useContext(ViewportContext);
	const breakpoint = 950;

	return width < breakpoint ? <SideBar /> : <Header />;
};

export default Navbar;
