import { useState } from "react";
import styles from "../styles/Expert.module.css";
import Consultancy from "./Consultancy";
import { ChevronsRight } from "react-feather";
import ExpertImg from "../img/Group Talk.jpeg";

const Expert = () => {
	const [modalShow, setModalShow] = useState(false);

	return (
		<section id="expert" data-name="expert" className="expert">
			<div className="wrapper">
				<div className={styles.wrapped}>
					<div className={styles.imgContainer}>
						<img src={ExpertImg} alt="Expert Talk" className={styles.expertImage} />
					</div>
					<div className={styles.textContainer}>
						<p className={styles.paragraph}>
							We are a world-class Info Tech Consulting and Software Development firm
							redefining the future’s possibilities. We solve tomorrow’s problems
							today, by analyzing the challenge and transforming ideas to create
							technologically driven results that will empower the future.
						</p>
						<div className={styles.buttonContainer}>
							<button className={styles.buttonLg} onClick={() => setModalShow(true)}>
								{" "}
								Talk to an expert <ChevronsRight />
							</button>
						</div>

						<Consultancy show={modalShow} onHide={() => setModalShow(false)} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Expert;
