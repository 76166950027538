import React from 'react';
// import {useState} from 'react';
// import styles from "../styles/ITStrategy.module.css";
import {Modal} from 'react-bootstrap';

const DisplayModal = (props) => {

// const [fullscreen, setFullscreen] = useState(true);
//   const [show, setShow] = useState(false);

//   function handleShow(breakpoint) {
//     setFullscreen(breakpoint);
//     setShow(true);
//   }

  return (
      <>
       <Modal {...props}
       >
        {/* <Modal.Header className={styles.closeButton} closeButton> */}
          {/* <Modal.Title>Modal</Modal.Title> */}
        {/* </Modal.Header> */}
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
      </>
  )
}

export default DisplayModal;