import React, {useState} from "react";
import styles from "../styles/Consultancy.module.css";
import ConsultantImg from "../img/Customer Care.jpeg";
import DisplayModal from "./DisplayModal";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const Consultancy = ({ show, onHide }) => {
	const [isSent, setIsSent] = useState(false);
	const [name, setName] = useState("");
	const [Phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [message, setMessage] = useState("");
	const [captcha, setCaptcha] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		const newMessage = {
			name,
			Phone,
			email,
			company,
			message,
			captcha,
		};
		try {
			const res = await axios.post(
				"https://mailer-api-server.herokuapp.com/api/v1/consultant",
				newMessage,
			);
			if (res.data.success === true) {
				setName("");
				setPhone("");
				setEmail("");
				setCompany("");
				setMessage("");
				setCaptcha("");
				setIsSent(true);
				setTimeout(() => { 
					setIsSent(false);
				}, 3000);
			}
		} catch (err) {
			if (err.response.data.success === false) {
				setIsSent(false);
			}
		}
	};

	return (
		<>
			<DisplayModal
				show={show}
				fullscreen={true}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={onHide}
			>
				<section id="consultancy" className="consultancy" data-name="consultancy">
					{/* <div className="wrapper"> */}
					<div className={styles.container}>
						<button className="modalCloseBtn" onClick={onHide}>
							&#10006;
						</button>
						<div className={styles.headContainer}>
							<h2 className={styles.mainHeader}>TALK TO AN EXPERT</h2>
						</div>
						<div className={styles.containerBlock}>
							<div className={styles.contactLeft}>
								<div className={styles.contactHeading}>
									Chat with our experts for consultancy
								</div>
								<div className={styles.contactBody}>
									<form className={styles.form} onSubmit={handleSubmit}>
										<div className={styles.formInput}>
											<input
												type="name"
												placeholder="Name*"
												required
												value={name}
												onChange={(e) => setName(e.target.value)}
											/>
										</div>
										<div className={styles.formInput}>
											<input
												type="email"
												placeholder="Email*"
												required
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</div>
										<div className={styles.formInput}>
											<input
												type="telephone"
												placeholder="Telephone*"
												required
												value={Phone}
												onChange={(e) => setPhone(e.target.value)}
											/>
										</div>
										<div className={styles.formInput}>
											<input
												type="company"
												placeholder="Company's name*"
												required
												value={company}
												onChange={(e) => setCompany(e.target.value)}
											/>
										</div>
										<div className={styles.formTextArea}>
											<textarea
												placeholder="Any specific requests?"
												required
												value={message}
												onChange={(e) => setMessage(e.target.value)}
											></textarea>
										</div>
										<div className={styles.recaptcha}>
											<ReCAPTCHA
												className="g-recaptcha"
												sitekey="6LcrWE4gAAAAAP0PxrOkdw_usrJWmCa3H_F7vxza"
												onChange={(val) => setCaptcha(val)}
											/>
										</div>
										<div className={styles.formButton}>
											<input type="submit" value="Contact" />
										</div>
									</form>
									{isSent && (
										<div className={styles.successContainer}>
											<p className={styles.success}>Message Submitted Successfully!</p>
										</div>
									)}
								</div>
							</div>
							<div className={styles.contactRight}>
								<div className={styles.contactBody}>
									<div className={styles.imgContainer}>
										<img
											src={ConsultantImg}
											alt="ActiveListener"
											className={styles.consultantImage}
										/>
									</div>
								</div>
							</div>
						</div>
						<p className={styles.modalMessage}>
							For further information from our experts, take full advantage of our
							support team, be rest assured we’ll respond and all details are secured
							and not sent to any third party, or unless we are required to do so by
							law.
						</p>
						<p className={styles.slogan}>More Than Tech...</p>
					</div>
					{/* </div> */}
				</section>
			</DisplayModal>
		</>
	);
};

export default Consultancy;
